import * as React from "react";
import anyspherelogo from "../images/anysphere-logo3.svg";
import { Link } from "gatsby";

const NavBar = (props: { bigLogo: boolean }) => {
  const imgClass = `md:${props.bigLogo ? "h-16" : "h-12"} h-12`;
  return (
    <div className="px-4 pt-6 w-full flex flex-row gap-10 pr-10 items-top dark:text-white">
      <Link to="/">
        <img className={imgClass} src={anyspherelogo} alt="Anysphere logo." />
      </Link>
      <div className="flex-grow"></div>
      {/* <div className="h-12 grid">
        <Link className="hover:underline place-self-center" to="/">
          Home
        </Link>
      </div> */}
      <div className="h-12 grid">
        <Link
          className="hover:underline place-self-center text-asbrown-dark"
          to="/team"
        >
          Team
        </Link>
      </div>
      <div className="h-12 grid">
        <Link
          className="hover:underline place-self-center text-asbrown-dark"
          to="/blog"
        >
          Blog
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
